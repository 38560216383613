<template>
  <QuestionBase :question="question" :errors="[...errorsPost.expiry_month, ...errorsPost.expiry_year]"
                :valid="!!form.expiry_month && !!form.expiry_year">

    <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
      <div class="question question-multipart field-20">
        <label>Expiry Month</label>
        <InputBasic
            v-model="form.expiry_month"
            :errors-post="errorsPost.expiry_month"
            :db="db"
            :args="argsMonth"
            :skipped="skipped"
            :noSave="true"
            @skipped="skipped=$event"
            @serverErrors="serverErrors=$event"
            @jsErrors="jsErrors=$event"
        />
      </div>
      <div class="question question-multipart field-20">
        <label>Expiry Year</label>
        <InputBasic
            v-model="form.expiry_year"
            :errors-post="errorsPost.expiry_year"
            :db="db"
            :args="argsYear"
            :skipped="skipped"
            :noSave="true"
            @skipped="skipped=$event"
            @serverErrors="serverErrors=$event"
            @jsErrors="jsErrors=$event"
        />
      </div>
    </div>
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";

import QuestionBase from "../../QuestionBase";
import InputBasic from "../../inputs/InputBasic";

export default {
  name: 'CardDetails',
  mixins: [questionLoadHelpers],
  components: {
    InputBasic,
    QuestionBase
  },
  props: {
    value: {
      type: Object,
      required: false
    },
    errorsPost: {
      type: Object,
      required: true
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: null,
        subTitle: null,
        tip: null
      },
      db: {
        saveLocation: null,
        saveField: null
      },
      argsMonth: {
        placeholder: 'e.g. 01',
        label: null,
        type: 'text',
        skippable: false,
        required: true,
        inputClass: 'field-100'
      },
      argsYear: {
        placeholder: 'e.g. 25',
        label: null,
        type: 'text',
        skippable: false,
        required: true,
        inputClass: 'field-100'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
