<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Request for Payment</h3>
    <template v-if="paymentRequest">
      <p>
        Please pay {{ currencyInvoice(paymentRequest.amount) }} for invoice totaling
        {{ currencyInvoice(paymentRequest.invoice.total) }} for {{ paymentRequest.invoice.transaction.type }} services
        provided to {{ clientNameList({clients: paymentRequest.invoice.transaction.clients}) }}.
      </p>
    </template>

    <CardNumber
        v-model="form"
        :errors-post="errors"
    />

    <CardDetails
        v-model="form"
        :errors-post="errors"
    />

    <CardHolder
       v-model="form"
       :errors-post="errors"
    />

    <b-button @click="pay">Pay</b-button>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import {currencyFormat} from "@/mixins/currencyFormat";
import {clientHelpers} from "@/mixins/clientHelpers";
import { paymentHelpers } from "@/views/questionnaires/Payment/paymentHelpers";

import CardNumber from "@/components/common/questionnaires/question/questions/payment/CardNumber";
import CardHolder from "@/components/common/questionnaires/question/questions/payment/CardHolder";
import CardDetails from "@/components/common/questionnaires/question/questions/payment/CardDetails";

export default {
  name: 'CardForm',
  mixins: [fieldsetHelpers, currencyFormat, clientHelpers, paymentHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    CardDetails,
    CardHolder,
    CardNumber
  },
  data() {
    return {
      label: 'Payment Details', // used for steps progress
      errors: {
        number: [],
        expiry_month: [],
        expiry_year: [],
        security_code: [],
        full_name: []
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    paymentRequest() {
      return this.$store.getters.paymentRequest
    }
  },
  methods: {}
};
</script>
