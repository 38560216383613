<template>
  <QuestionnaireBase
      :title="title"
      :form="form"
      :finishFunction="null"
      :showNavigationButtons="false">

    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";
import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";
import CardForm from "@/components/common/questionnaires/fieldsets/payment/CardForm";

export default {
  name: "Payment",
  mixins: [loadHelpers],
  components: {
    QuestionnaireBase,
    CardForm
  },
  data() {
    return {
      name: 'Payment',
      title: "Payment",
      url: 'payment_request',
      order: [ // imported components required here to load
        'CardForm'
      ],
      expiryAccessCodeOnFinish: false,
      storeExtraGetData: this.storePaymentDetails
    };
  },
  methods: {
    storePaymentDetails (data) {
      this.$store.commit('paymentRequest', data.payment_request)
    }
  }
};
</script>
