import {httpQuestionnaire} from "@/services";
import {toast} from '@/mixins/toast'

export const paymentHelpers = {
    mixins: [toast],
    mounted() {
        // required for iframe access to this files methods
        window.c_1 = this
    },
    methods: {
        async pay() {
            this.clearErrors()
            httpQuestionnaire.post('payment', this.form).then(
                async response => {
                    console.log(response.data)

                    let data = response.data

                    if (data.success && data.action === 'PAYMENT_PROCESSED') {
                        this.show.loading = false;
                        this.show.success = true;
                        this.show.orderId = response.data.order_id;
                    } else if (data.success && data.action === 'AUTHENTICATE_BROWSER') {
                        console.log('authenticate browser')
                        await this.addAuthenticateElement(data.element)
                    }
                }
            ).catch(
                error => {
                    console.log(error)
                    this.handleErrors(error.response.data)
                }
            )
            return true
        },
        handleErrors(data) {
            if ('errors' in data) {
                // first data response errors
                let keys = Object.keys(data.errors);
                for (let i in keys) {
                    if (keys[i] === 'non_field_errors') {
                        for (let y = 0; y < data.errors[keys[i]].length; y++) {
                            this.toastError(data.errors[keys[i]][y])
                        }
                    }
                    this.errors[keys[i]] = data.errors[keys[i]];
                }
            } else {
                // server form serializer errors
                let keys = Object.keys(data);
                for (let i in keys) {
                    if (keys[i] === 'non_field_errors') {
                        for (let y = 0; y < data[keys[i]].length; y++) {
                            this.toastError(data[keys[i]][y])
                        }
                    }
                    this.errors[keys[i]] = data[keys[i]];
                }
            }
        },
        clearErrors() {
            // clear server for serializer errors
            let keys = Object.keys(this.errors);
            for (let i in keys) {
                this.errors[keys[i]] = [];
            }
        },
        async addAuthenticateElement(newElement) {
            console.log('add browser auth element', newElement)
            // adds and run javascript for authenticate 3ds
            // let existingElement = document.getElementById(name)
            // if (existingElement) existingElement.remove()

            // remove previous elements created
            var elem = document.getElementById('promptModalContent')
            elem.innerHTML = newElement

            elem.querySelectorAll("script")
                .forEach(x => {
                        var script = document.createElement("script");
                        script.appendChild(document.createTextNode(x.innerText));
                        elem.appendChild(script)
                    }
                )
            return true
        },
    }
}
