<template>
  <QuestionBase :question="question" :errors="[...errorsPost.number, ...errorsPost.security_code]" :valid="!!form.number && !!form.security_code">

    <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
      <div class="question question-multipart field-66">

        <label>Card Number</label>
        <InputBasic
            v-model="form.number"
            :errors-post="errorsPost.number"
            :db="db"
            :args="argsNumber"
            :skipped="skipped"
            :noSave="true"
            @skipped="skipped=$event"
            @serverErrors="serverErrors=$event"
            @jsErrors="jsErrors=$event"
        />
      </div>
      <div class="question question-multipart field-33">

        <label>Security Code</label>
        <InputBasic
            v-model="form.security_code"
            :errors-post="errorsPost.security_code"
            :db="db"
            :args="argsSecurity"
            :skipped="skipped"
            :noSave="true"
            @skipped="skipped=$event"
            @serverErrors="serverErrors=$event"
            @jsErrors="jsErrors=$event"
        />
      </div>
    </div>
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";

import QuestionBase from "../../QuestionBase";
import InputBasic from "../../inputs/InputBasic";

export default {
  name: 'CardNumber',
  mixins: [questionLoadHelpers],
  components: {
    InputBasic,
    QuestionBase
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    errorsPost: {
      type: Object,
      required: true
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'Card Details',
        subTitle: null,
        tip: null
      },
      db: {
        saveLocation: null,
        saveField: null
      },
      argsNumber: {
        placeholder: 'e.g. 1112 23232 1211 1222',
        label: null,
        type: 'text',
        skippable: false,
        required: true,
        inputClass: 'field-100'
      },
      argsSecurity: {
        placeholder: 'e.g. 111',
        label: null,
        type: 'text',
        skippable: false,
        required: true,
        inputClass: 'field-100'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
